<template>
  <div class="main">
    <div class="modal">
      <div class="modal-left"></div>
      <div class="registration-form modal-right">
        <div class="form-header">
          <img class="header-lgo" src="@/assets/img/logo.png" alt="log" />
          <div class="header_title">Зарегистрируйся сейчас и получи</div>
          <div class="header-desc">
            <div class="header-desc-item">
              <img class="header-desc-1000" src="@/assets/img/1000.png" alt="1000" />
              <p>
                бесплатных <br />
                вращений
              </p>
            </div>
            <div class="header-desc-item">и</div>
            <div class="header-desc-item">
              <img class="header-desc-100" src="@/assets/img/100.png" alt="100%" />
              <p>
                на <br />
                депозит
              </p>
            </div>
          </div>
        </div>
        <form
          action="#"
          id="registrationForm"
          :class="{'form_disabled': isRequestSending}"
          @submit.prevent="submitForm"
        >
          <h2 class="title">Регистрация</h2>
          <div class="form-group">
            <input type="tel" id="phone" value="+7" v-model="phone" />
            <div class="shadowPassword"><img src="@/assets/img/phone.svg" alt="" /></div>
          </div>
          <div class="form-group">
            <input
              type="password"
              id="password"
              placeholder="Введите ваш пароль"
              required
              v-model="password"
            />
            <div class="shadowPassword"><img src="@/assets/img/key.png" alt="" /></div>
          </div>

          <div class="form-group">
            <input type="text" id="promo" placeholder="Введите код" v-model="promocode" />
          </div>
          <div class="form-radio">
            <h2>Выберите валюту:</h2>
            <div class="radio-cont">
              <div class="radio-block">
                <input type="radio" id="RUB" value="RUB" name="currency" checked v-model="currency" />
                <label for="RUB">RUB</label>
              </div>
              <div class="radio-block">
                <input type="radio" id="UAH" value="UAH" name="currency" v-model="currency" />
                <label for="UAH">UAH</label>
              </div>
            </div>
          </div>

          <div class="form-check">
            <label class="perm-check">
              <input type="checkbox" checked required id="check"/>
              <p>
                Я согласен с <a href="#">Правилами и Условиями</a> и
                <a href="#">Защитой Информации</a>, Мне исполнилось
                <a href="#"><span class="BebasNeue">18</span> лет</a>, Я хочу
                получать новости по <a href="#">e-mail</a> и
                <a href="#">sms</a>
              </p>
            </label>
          </div>
          <button type="submit" class="submit-btn" id="submit-btn">
            Зарегистрироваться
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import axios from 'axios'

export default {
  name: 'landing-page',
  data () {
    return {
      phone: '+7',
      password: '',
      country: 'RU',
      currency: 'RUB',
      promocode: 'OPA',
      checkbox: false,
      isRequestSending: false
    }
  },
  methods: {
    submitForm () {
      if (this.isRequestSending) {
        return
      }

      this.isRequestSending = true

      const data = {
        user: {
          country: this.country,
          currency: this.currency,

          bonus_code: this.promocode,
          captcha: '',
          parent_refcode: '',
          profile_attributes: {
            age_acceptance: true,
            terms_acceptance: true,
            game_currency: this.currency,
            receive_newsletters: false,
            receive_promos: false,
            last_name: '',
            first_name: '',
            receive_sms_promos: false,
            nickname: '',
            currency: this.currency,
            language: 'ru',
            country: this.country
          },
          phone: {
            country: this.country,
            number: `${this.phone}`
          },
          password: this.password,
          password_confirmation: this.password
        },
        lang: 'ru'
      }

      console.log(data)

      this.fetchData(data)
    },
    fetchData (data) {
      axios.post('/script.php', data, {
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
        .then((response) => {
          this.isRequestSending = false

          if (response.status === 200 || response.statusText === 'OK') {
            if (response.data) {
              const { token, url } = response.data

              if (token && token.length) {
                useToast().success('Успешная регистрация! Перенаправление...')
                document.location.href = url

                return
              }
            }

            useToast().error('Ошибка при регистрации')

            return
          }

          console.log('error', response)
          useToast().error('Ошибка при отправке запроса')
        })
        .catch((error) => {
          this.isRequestSending = false
          console.log('request error', error)
          useToast().error('Ошибка при отправке запроса')
        })
    }
  }
}
</script>

<style src="./style.scss" lang="scss"></style>
